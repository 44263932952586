import styled from "styled-components"

export const Section = styled.section`
  & > div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    width: 100%;
    height: calc(100vw * 1000 / 2400);
    min-height: 500px;
    position: relative;
  }

  & .hero-gradient {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 1),
      rgba(55, 255, 255, 0) 50%
    );

    @media screen and (max-width: 1023px) {
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 1),
        rgba(55, 255, 255, 0) 75%
      );
    }

    @media screen and (max-width: 767px) {
      background-image: radial-gradient(
        circle,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0) 75%
      );
    }
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 50px 0;

  @media screen and (max-width: 1240px) {
    padding: 50px 20px;
  }

  @media screen and (max-width: 767px) {
    align-items: center;
    text-align: center;
  }

  & div {
    margin: 12px 0;
    max-width: 550px;
    color: #154d7c;

    @media screen and (max-width: 1240px) {
      margin: 10px 0;
      max-width: 450px;
    }

    @media screen and (max-width: 1023px) {
      max-width: 400px;
    }
  }

  & div.heading {
    font-size: 56px;
    line-height: 56px;
    font-weight: 600;

    @media screen and (max-width: 1240px) {
      font-size: 48px;
      line-height: 48px;
    }

    @media screen and (max-width: 1023px) {
      font-size: 42px;
      line-height: 42px;
    }
  }

  & div.subheading {
    font-size: 32px;
    line-height: 32px;
    font-weight: 600;

    @media screen and (max-width: 1240px) {
      font-size: 24px;
      line-height: 24px;
    }
  }

  & div.subheadingsm {
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;

    @media screen and (max-width: 1240px) {
      font-size: 16px;
      line-height: 16px;
    }
  }

  & div:first-child {
    margin-top: 0;
  }

  & div:last-child {
    margin-bottom: 0;
  }
`
