import React from "react"
import bg from "../../../img/background-hero-1.jpg"
import HighlightedLink from "../../HighlightedLink"
import { Section, Container } from "./styled"

const Hero = () => {
  return (
    <Section>
      <div style={{ backgroundImage: `url(${bg})` }}>
        <div className="hero-gradient"></div>
        <Container className="main-page">
          <div className="heading">
            There’s a better way to upgrade your A/C.
          </div>
          <div className="subheading">
            Buy online. No middlemen, no upsells, save thousands.
          </div>
          <div className="subheadingsm">*Installation included.</div>
          <HighlightedLink to="/buy/1" name="Get Started" />
        </Container>
      </div>
    </Section>
  )
}

export default Hero
