import React from "react"
import { Section, Container, FlexContainer } from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/free-solid-svg-icons"

const Testimonials = () => {
  return (
    <Section>
      <div>
        <Container className="main-page">
          <div className="heading">Don’t Take Our Word For It</div>
          <div className="subheading">Here’s What Our Customers Have Said</div>
          <FlexContainer>
            <div className="testimonial">
              <div className="quote">
                “Great service! The technician is wonderful. Very polite,
                competent and efficient. Answered all my questions.”
              </div>
              <div className="stars">
                {["", "", "", "", ""].map((item, index) => {
                  return (
                    <div key={index}>
                      <FontAwesomeIcon icon={faStar} />
                      <span className="text-hide">Star Icon</span>
                    </div>
                  )
                })}
              </div>
              <div className="name">Susan, Boynton Beach, FL</div>
            </div>
            <div className="testimonial">
              <div className="quote">
                “Peace of mind; Couldn’t live in florida without Mechanical
                Air’s knowledgeable/helpful service technicians It’s no sweat!”
              </div>
              <div className="stars">
                {["", "", "", "", ""].map((item, index) => {
                  return (
                    <div key={index}>
                      <FontAwesomeIcon icon={faStar} />
                      <span className="text-hide">Star Icon</span>
                    </div>
                  )
                })}
              </div>
              <div className="name">Richmond, Delray Beach, FL</div>
            </div>
          </FlexContainer>
        </Container>
      </div>
    </Section>
  )
}

export default Testimonials
