import styled from "styled-components"

export const Section = styled.section`
  & > div {
    background-color: var(--blue);
    width: 100%;
    padding: 40px 0;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;

  & > div {
    margin: 10px 0;
  }

  & > div:first-child {
    margin-top: 0;
  }

  & > div:last-child {
    margin-bottom: 0;
  }

  & > div.heading {
    font-size: 44px;
    line-height: 44px;
    font-weight: 600;
    color: #fff;

    @media screen and (max-width: 1240px) {
      font-size: 36px;
      line-height: 36px;
    }
    @media screen and (max-width: 1023px) {
      font-size: 32px;
      line-height: 32px;
    }
  }

  & > div.subheading {
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
    color: #fff;

    @media screen and (max-width: 1240px) {
      font-size: 20px;
      line-height: 20px;
    }
    @media screen and (max-width: 1023px) {
      font-size: 18px;
      line-height: 18px;
    }
  }
`

export const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 767px) {
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }

  & > div {
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @media screen and (max-width: 767px) {
      text-align: center;
      padding: 0;
      margin: 20px 0;
    }
  }

  & > div:first-child {
    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }

  & > div:last-child {
    @media screen and (max-width: 767px) {
      margin-bottom: 0;
    }
  }

  & > div > div {
    margin: 10px 0;

    @media screen and (max-width: 767px) {
      margin: 5px 0;
    }
  }

  & > div > div:first-child {
    margin-top: 0;
  }

  & > div > div:last-child {
    margin-bottom: 0;
  }

  & .testimonial {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px 20px 20px;
  }

  & .testimonial > div {
    margin: 5px 0;
  }

  & .testimonial > div:first-child {
    margin-top: 0;
  }

  & .testimonial > div:last-child {
    margin-bottom: 0;
  }

  & .testimonial > div.stars {
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
  }

  & .testimonial > div.stars > div {
    margin: 0 2px;
    border-radius: 50%;
    height: 36px;
    width: 36px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--blue);
  }
`
