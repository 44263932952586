import styled from "styled-components"

export const Section = styled.section`
  & > div {
    background-color: fff;
    width: 100%;
    padding: 10px 0 20px 0;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;

  @media screen and (max-width: 1023px) {
  }

  & > div {
    margin: 10px 0;
  }

  & > div:first-child {
    margin-top: 0;
  }

  & > div:last-child {
    margin-bottom: 0;
  }

  & > div.heading {
    font-size: 44px;
    line-height: 44px;
    font-weight: 600;
    color: #154d7c;

    @media screen and (max-width: 1240px) {
      font-size: 36px;
      line-height: 36px;
    }

    @media screen and (max-width: 1023px) {
      font-size: 32px;
      line-height: 32px;
    }
  }

  & .subheading {
    @media screen and (max-width: 1240px) {
      font-size: 20px;
      line-height: 20px;
    }

    @media screen and (max-width: 1023px) {
      font-size: 18px;
      line-height: 18px;
    }
  }

  & .icon {
    @media screen and (max-width: 1023px) {
      font-size: 56px;
      height: 120px;
      width: 120px;
    }
  }
`

export const FlexContainer = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 767px) {
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }

  & > div {
    flex: 1;
    text-align: center;
    padding: 0 20px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @media screen and (max-width: 767px) {
      text-align: center;
      padding: 0;
      margin: 20px 0;
    }
  }

  & > div:first-child {
    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }

  & > div:last-child {
    @media screen and (max-width: 767px) {
      margin-bottom: 0;
    }
  }

  & > div > div {
    margin: 15px 0;

    @media screen and (max-width: 767px) {
      margin: 5px 0;
    }
  }

  & > div > div:first-child {
    margin-top: 0;
  }

  & > div > div:last-child {
    margin-bottom: 0;
  }

  & .subheading {
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
    color: #154d7c;
  }

  & .icon {
    font-size: 70px;
    border-radius: 50%;
    height: 150px;
    width: 150px;
    background-color: #154d7c;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
`
