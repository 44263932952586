import React from "react"
import Layout from "../elements/Layout"
import Helmet from "../elements/Helmet"
import Header from "../components/Header"
import Hero from "../components/index/Hero"
import Alert from "../components/index/Alert"
import Steps from "../components/index/Steps"
import Testimonials from "../components/index/Testimonials"
import Footer from "../components/Footer"

const Index = () => {
  return (
    <Layout>
      <Helmet
        index={true}
        name="Buy New AC Units, Systems & Filters"
        description="Save time and money when using Mechanical Air Conditioning’s e-commerce web site for fast and easy purchase and concierge installation of your new AC system in St.Lucie, Martin and Palm Beach County FL."
        slug=""
      />
      <Header />
      <Hero />
      <Alert />
      <Steps />
      <Testimonials />
      <Footer />
    </Layout>
  )
}

export default Index
