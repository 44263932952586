import React from "react"
import HighlightedLink from "../../HighlightedLink"
import { Section, Container } from "./styled"

const Alert = () => {
  return (
    <Section>
      <div>
        <Container className="main-page">
          <div>
            Order now and receive a one-year maintenance plan free. No
            contracts, no obligations thereafter.
          </div>
          <HighlightedLink to="/special" name="Learn More" invert={true} />
        </Container>
      </div>
    </Section>
  )
}

export default Alert
