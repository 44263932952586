import styled from "styled-components"

export const Section = styled.section`
  & > div {
    background-color: #6daa29;
    width: 100%;
    padding: 20px 0;
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  color: #fff;
  align-items: center;
  text-align: center;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  & > div {
    padding: 0 10px;

    @media screen and (max-width: 1023px) {
      padding: 10px 0;
    }
  }

  & > div:first-child {
    padding-left: 0;

    @media screen and (max-width: 1023px) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  & > div:last-child {
    padding-right: 0;

    @media screen and (max-width: 1023px) {
      padding-right: 0;
    }
  }
`
