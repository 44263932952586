import React from "react"
import HighlightedLink from "../../HighlightedLink"
import { Section, Container, FlexContainer } from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faHome,
  faShoppingCart,
  faConciergeBell,
} from "@fortawesome/pro-solid-svg-icons"

const Steps = () => {
  return (
    <Section>
      <div>
        <Container className="main-page">
          <div className="heading">Here’s How It Works</div>
          <FlexContainer>
            <div>
              <div className="subheading">Take Our Survey</div>
              <div className="icon">
                <FontAwesomeIcon icon={faHome} />
                <span className="text-hide">House Icon</span>
              </div>
              <div>
                Just answer a few simple questions and our “HVAC Match”
                technology will accurately return all the high efficiency
                systems that fit your needs.
              </div>
            </div>
            <div>
              <div className="subheading">Buy With Confidence</div>
              <div className="icon">
                <FontAwesomeIcon icon={faShoppingCart} />
                <span className="text-hide">Shopping Cart Icon</span>
              </div>
              <div>
                Research the matching options and select the system YOU are most
                comfortable with. Order direct and securely through our website
                and save.{" "}
              </div>
            </div>
            <div>
              <div className="subheading">Enjoy Concierge Service</div>
              <div className="icon">
                <FontAwesomeIcon icon={faConciergeBell} />
                <span className="text-hide">Concierge Bell Icon</span>
              </div>
              <div>
                Upon receiving your order, our concierge team will contact you
                to perform a site inspection and schedule your install.
                Satisfaction guaranteed.
              </div>
            </div>
          </FlexContainer>
          <HighlightedLink to="/how" name="Learn More" blue={true} />
        </Container>
      </div>
    </Section>
  )
}

export default Steps
